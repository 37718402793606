import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DataReviewSidebar from './DataReviewSidebar';
import CombinedNetworkGraph from './CombinedNetworkGraph';
import './DataReview.css';

const Legend = ({ legendData }) => (
    <div className="legend-container" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', margin: '10px' }}>
        {Object.entries(legendData).map(([fileName, color]) => (
            <div key={fileName} style={{ display: 'flex', alignItems: 'center', marginRight: '15px', marginBottom: '5px' }}>
                <div style={{
                    width: '20px',
                    height: '20px',
                    backgroundColor: color,
                    marginRight: '5px'
                }} />
                <span>{fileName}</span>
            </div>
        ))}
    </div>
);

const DataReview = () => {
    const [collections, setCollections] = useState([]);
    const [selectedCollection, setSelectedCollection] = useState('');
    const [collectionName, setCollectionName] = useState('');
    const [collectionDescription, setCollectionDescription] = useState('');
    const [knGraphOption, setKnGraphOption] = useState('kn_graph_full');
    const [directories, setDirectories] = useState([]);
    const [selectedDirectory, setSelectedDirectory] = useState('');
    const [directoryFiles, setDirectoryFiles] = useState([]);
    const [filesData, setFilesData] = useState([]);
    const [graphDataSets, setGraphDataSets] = useState([]);
    const [legendData, setLegendData] = useState({});
    const [graphQuery, setGraphQuery] = useState('');
    const [tableData, setTableData] = useState([]);
    const [topEntries, setTopEntries] = useState({ node_1: [], node_2: [], edge: [] });
    const [activeCollectionFiles, setActiveCollectionFiles] = useState([]);

    const baseURL = 'https://mdlpearl.ddns.net';

    useEffect(() => {
        axios.get(`${baseURL}/api/names`)
            .then((response) => setCollections(response.data))
            .catch((error) => console.error('Error fetching collections:', error));

        axios.get(`${baseURL}/api/directories`)
            .then((response) => setDirectories(response.data))
            .catch((error) => console.error('Error fetching directories:', error));
    }, []);

    const handleCollectionChange = (name) => {
        if (name === selectedCollection) {
            setSelectedCollection('');
            resetGraphData();
            return;
        }

        setSelectedCollection(name);

        axios.get(`${baseURL}/api/files/${name}`)
            .then((response) => {
                if (!Array.isArray(response.data)) {
                    throw new Error('File IDs response is not an array');
                }

                setActiveCollectionFiles(response.data);
                return axios.post(`${baseURL}/api/query-datatable`, { selectedIds: response.data });
            })
            .then((response) => {
                if (Array.isArray(response.data)) {
                    setFilesData([...filesData, ...response.data]);
                } else {
                    console.error('Unexpected response format:', response.data);
                    throw new Error('Query did not return an array of data');
                }
            })
            .catch((error) => {
                console.error('Error fetching files or querying articles:', error);
            });
    };

    const handleDirectorySelection = (directory) => {
        setSelectedDirectory(directory);

        axios.get(`${baseURL}/api/files-in-directory/${directory}`)
            .then((response) => {
                if (Array.isArray(response.data)) {
                    setDirectoryFiles(response.data);
                } else {
                    console.error('Unexpected response format:', response.data);
                }
            })
            .catch((error) => {
                console.error('Error fetching files from directory:', error);
            });
    };

    const toggleFileInList = (file, selectAll = false) => {
        setFilesData((prevFiles) => {
            if (selectAll) {
                return Array.from(new Set([...prevFiles, ...file]));
            } else {
                const fileExists = prevFiles.find(f => f.id === file.id);
                return fileExists ? prevFiles.filter(f => f.id !== file.id) : [...prevFiles, file];
            }
        });
    };

    const handleClearFiles = () => {
        setFilesData([]);
        setActiveCollectionFiles([]);
        setSelectedCollection('');
        setDirectoryFiles([]);
        setSelectedDirectory('');
        resetGraphData();
    };

    const handleCreateCollection = () => {
        if (!collectionName) {
            alert('Collection Name is required');
            return;
        }

        const files = filesData.map(file => file.id);

        axios.post(`${baseURL}/api/create-collection`, {
            name: collectionName,
            description: collectionDescription,
            files: files
        })
        .then(() => {
            alert('Collection created successfully');
            setCollectionName('');
            setCollectionDescription('');
            handleClearFiles();
        })
        .catch((error) => {
            console.error('Error creating collection:', error);
            alert('Failed to create the collection');
        });
    };

    const handleCreateKnowledgeGraph = () => {
        try {
            resetGraphData();

            const legend = {};

            const graphDataSets = filesData.map((file, index) => {
                const queryResults = graphQuery ? refineGraphData(file[knGraphOption], graphQuery) : file[knGraphOption];

                if (queryResults) {
                    const color = `hsl(${index * 137.508}, 50%, 50%)`;
                    legend[file.file_name] = color;
                    return {
                        nodesData: structureGraphData(queryResults).nodes.map(node => ({ ...node, color })),
                        edgesData: structureGraphData(queryResults).edges,
                        fileName: file.file_name
                    };
                }
                return null;
            }).filter(Boolean);

            setLegendData(legend);
            setGraphDataSets(graphDataSets);

        } catch (error) {
            console.error('Error preparing knowledge graph:', error);
            alert('Failed to prepare the knowledge graph');
        }
    };

    const structureGraphData = (rawData) => {
        try {
            const jsonData = JSON.parse(rawData);
            const nodes = [];
            const edges = [];

            jsonData.forEach(item => {
                nodes.push({ id: item.node_1, label: item.node_1 });
                nodes.push({ id: item.node_2, label: item.node_2 });
                edges.push({ from: item.node_1, to: item.node_2, label: item.edge });
            });

            return { nodes, edges };
        } catch (e) {
            console.error('Failed to parse graph data:', e);
            return { nodes: [], edges: [] };
        }
    };

    const processKGData = (data) => {
        let combinedData = [];
        data.forEach(fileData => {
            try {
                const jsonData = JSON.parse(fileData[knGraphOption]);
                combinedData = [...combinedData, ...jsonData];
            } catch (error) {
                console.error('Error parsing file data to JSON', error);
            }
        });

        const nodes = {};
        const edges = {};

        combinedData.forEach(item => {
            const { node_1, node_2, edge } = item;
            if (node_1 && node_2 && edge) {
                nodes[node_1] = (nodes[node_1] || 0) + 1;
                nodes[node_2] = (nodes[node_2] || 0) + 1;
                edges[edge] = (edges[edge] || 0) + 1;
            }
        });

        setTableData(combinedData);

        setTopEntries({
            node_1: Object.entries(nodes).sort(([, a], [, b]) => b - a).slice(0, 5),
            node_2: Object.entries(nodes).sort(([, a], [, b]) => b - a).slice(0, 5),
            edge: Object.entries(edges).sort(([, a], [, b]) => b - a).slice(0, 5)
        });
    };

    const refineGraphData = (graphData, query) => {
        try {
            let results = JSON.parse(graphData);
            const queries = query.split(',').map(q => q.trim().toLowerCase());

            results = results.filter(item => {
                return queries.some(q =>
                    item.node_1.toLowerCase().includes(q) ||
                    item.node_2.toLowerCase().includes(q) ||
                    item.edge.toLowerCase().includes(q)
                );
            });

            return JSON.stringify(results);
        } catch (error) {
            console.error('Error refining graph data:', error);
            return graphData;
        }
    };

    const resetGraphData = () => {
        setGraphDataSets([]);
        setLegendData({});
    };

    return (
        <div className="data-review-container">
            <DataReviewSidebar
                collections={collections}
                selectedCollection={selectedCollection}
                setSelectedCollection={handleCollectionChange}
                knGraphOption={knGraphOption}
                setKnGraphOption={(value) => setKnGraphOption(value)}
                directories={directories}
                selectedDirectory={selectedDirectory}
                handleDirectorySelection={handleDirectorySelection}
                directoryFiles={directoryFiles}
                toggleFileInList={toggleFileInList}
                files={filesData}
            />
            <div className="table-wrapper">
                <h2>Data Review</h2>
                <p>Here you can review and analyze data from the selected collections and files.</p>

                <input
                    type="text"
                    className="uniform-input"
                    placeholder="Enter Graph Query (comma-separated)"
                    value={graphQuery}
                    onChange={(e) => setGraphQuery(e.target.value)}
                />

                <div className="button-container">
                    <button onClick={handleClearFiles}>Clear Selected Files</button>
                    <button onClick={handleCreateCollection}>Create Collection</button>
                    <button onClick={handleCreateKnowledgeGraph}>Create Knowledge Graph</button>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '10px' }}>
                    <input
                        type="text"
                        className="uniform-input"
                        placeholder="Collection Name"
                        value={collectionName}
                        onChange={(e) => setCollectionName(e.target.value)}
                    />
                    <textarea
                        className="uniform-input"
                        placeholder="Collection Description"
                        value={collectionDescription}
                        onChange={(e) => setCollectionDescription(e.target.value)}
                    />
                </div>

                {graphDataSets.length > 0 && (
                    <div>
                        <Legend legendData={legendData} />
                        <CombinedNetworkGraph fileDataSets={graphDataSets} />
                    </div>
                )}
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Directory</th>
                            <th>File Name</th>
                            <th>Key Concepts</th>
                            <th>Topic Title</th>
                            <th>Job Number</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filesData.map((file) => (
                            <tr key={file.id}>
                                <td>{file.id}</td>
                                <td>{file.directory}</td>
                                <td>{file.file_name}</td>
                                <td>{file.key_concepts}</td>
                                <td>{file.topic_title}</td>
                                <td>{file.job_number}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default DataReview;