import React from 'react';

const SettingsSidebar = ({ activeTab, setActiveTab }) => {
    return (
        <div className="sidebar">
            {['Main', 'Prompts', 'Data Upload', 'Other Settings'].map((tab) => (
                <button
                    key={tab}
                    className={`sidebar-button ${activeTab === tab ? 'active' : ''}`}
                    onClick={() => setActiveTab(tab)}
                >
                    {tab}
                </button>
            ))}
        </div>
    );
};

export default SettingsSidebar;
