import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Markdown from 'markdown-to-jsx';
import '../styles.css';
import ChatSettingsModal from './ChatSettingsModal';
import './ChatWindow.css';

const ChatWindow = ({ selectedChat }) => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [initialLoad, setInitialLoad] = useState(true);
    const [edited, setEdited] = useState(false);
    const [useRAG, setUseRAG] = useState(false);
    const [useText2SQL, setUseText2SQL] = useState(false);
    const [useAgentAPI, setUseAgentAPI] = useState(false);
    const [dbConfig, setDbConfig] = useState({
        db_name: '',
        db_user: '',
        db_password: '',
        db_host: ''
    });
    const [isChatSettingsOpen, setIsChatSettingsOpen] = useState(false);
    const [systemPrompt, setSystemPrompt] = useState('');
    const [selectedIds, setSelectedIds] = useState([]);

    useEffect(() => {
        if (selectedChat) {
            try {
                const loadedMessages = JSON.parse(selectedChat.chat_history);
                setMessages(loadedMessages);
                setInitialLoad(false);
                setEdited(false);
            } catch (err) {
                console.error('Error parsing chat history:', err);
                setMessages([]);
            }
        }
    }, [selectedChat]);

    const formatMarkdownTable = (tableMarkdown) => {
        return tableMarkdown.split("\n").map(line => line.trim()).join("\n");
    };

    const handleSend = async () => {
        if (!input.trim()) return;

        setEdited(true);
        const userMessage = { role: 'user', content: input };
        const systemMessage = {
            role: 'system',
            content: systemPrompt || 'You are an Intelligent Chatbot developed by Rock River Research. Respond to the user query with your indepth knowledge. Look to provide the user with a VERBOSE and STEP-BY-STEP response to their query. When provided with context or chat history use that to respond to the question'
        };

        const newMessages = [...messages, systemMessage, userMessage];

        setMessages(newMessages);
        setInput('');
        setError('');

        try {
            setLoading(true);

            if (useAgentAPI) {
                const response = await axios.post('https://mdlpearl.ddns.net/api/agent-messages', {
                    messages: newMessages
                });
                // const response = await axios.post('https://mdlpearl.ddns.net/api/agent-messages', {
                //     system: systemMessage,
                //     user: userMessage
                // });

                const updatedMessages = response.data.output.map(item => ({
                    role: item.role,
                    content: item.content
                }));
                
                console.log('Agent Messages Response:', response.data.output);
                //setMessages(updatedMessages);

                // Assume response.data.output contains the updated messages array
                //setMessages(response.data.output.messages);

                // Updating chat with returned messages
                if (response.data.output && Array.isArray(response.data.output)) {
                    setMessages(response.data.output);
                } else {
                    throw new Error('Invalid format from server response.');
                }

            }  else if (useText2SQL) {
                if (!dbConfig.db_name || !dbConfig.db_user || !dbConfig.db_password || !dbConfig.db_host) {
                    setError('Database configuration is missing.');
                    console.error('Database configuration is incomplete:', dbConfig);
                    return;
                }
                const response = await axios.post('https://mdlpearl.ddns.net/api/text2sql', { messages: newMessages, dbConfig });
                const resultContent = formatMarkdownTable(response.data.result);

                const text2sqlMessageContent = `
                    **Query Executed**:

                    \`\`\`sql
${response.data.query}
\`\`\`

                    **Results**:
                    ${resultContent}
                `;

                const text2sqlAssistantMessage = {
                    role: 'assistant',
                    content: text2sqlMessageContent
                };

                setMessages([...newMessages, text2sqlAssistantMessage]);
            }
            else {
                const endpoint = useRAG ? 'https://mdlpearl.ddns.net/api/rag-chat' : 'https://mdlpearl.ddns.net/api/chat';

                const payload = useRAG ?
                    {
                        userInput: input,
                        systemPrompt: systemPrompt || 'You are an Intelligent Chatbot developed by Rock River Research. Respond to the user query with your indepth knowledge. Look to provide the user with a VERBOSE and STEP-BY-STEP response to their query. When provided with context or chat history use that to respond to the question',
                        messages: newMessages // Include messages here
                    } : {
                        messages: newMessages
                    };

                const response = await axios.post(endpoint, payload);

                const assistantMessage = useRAG
                    ? { role: 'assistant', content: response.data.content }
                    : { role: 'assistant', content: response.data.choices[0].message.content };

                setMessages([...newMessages, assistantMessage]);
            }
        } catch (err) {
            console.error('Error sending message:', err);
            setError('Failed to send message. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleClear = async () => {
        const isNewOrUnchanged = initialLoad || (selectedChat && selectedChat.chat_history === JSON.stringify(messages));

        if (!edited && isNewOrUnchanged) {
            setMessages([]);
            return;
        }

        const promptName = selectedChat?.prompt_name || 'Default Prompt';
        const username = localStorage.getItem('username');  // Retrieve username from localStorage

        try {
            const response = await axios.post('https://mdlpearl.ddns.net/api/clear', {
                messages,
                promptName,
                username // Add username to request payload
            });

            if (response.status === 200) {
                setMessages([]);
                setEdited(false);
            } else {
                setError('Failed to clear chat on the server. Please try again.');
            }
        } catch (err) {
            setError('Failed to clear chat. Please try again.');
        }
    };

    return (
        <div
            className="chat-window"
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
                boxSizing: 'border-box',
                paddingTop: '60px'
            }}
        >
            <div
                className="chat-area"
                style={{
                    flex: 1,
                    overflowY: 'auto',
                    padding: '10px'
                }}
            >
                {error && <div className="error-message">{error}</div>}
                <div className="messages">
                    {messages.map((msg, index) => (
                        <div key={index} className={msg.role === 'user' ? 'user' : 'assistant'}>
                            <strong>{msg.role === 'user' ? 'User' : 'Assistant'}:</strong>
                            <Markdown>{msg.content}</Markdown>
                        </div>
                    ))}
                    {loading && <div>Loading...</div>}
                </div>
                <div
                    className="chat-input-bar"
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '10px',
                        borderTop: '1px solid #ccc',
                        backgroundColor: 'white',
                        position: 'sticky',
                        bottom: 0
                    }}
                >
                    <input
                        type="text"
                        style={{ flex: 1, marginRight: '10px' }}
                        value={input}
                        placeholder="Type your message..."
                        onChange={e => setInput(e.target.value)}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                handleSend();
                            }
                        }}
                    />
                    <button onClick={handleSend}>Send</button>
                    <button onClick={handleClear} style={{ marginLeft: '5px' }}>Clear</button>
                    <button onClick={() => setIsChatSettingsOpen(true)} style={{ marginLeft: '5px' }}>Chat Settings</button>
                </div>
            </div>
            <ChatSettingsModal
                isOpen={isChatSettingsOpen}
                onClose={() => setIsChatSettingsOpen(false)}
                useRAG={useRAG}
                setUseRAG={setUseRAG}
                useText2SQL={useText2SQL}
                setUseText2SQL={setUseText2SQL}
                useAgentAPI={useAgentAPI}
                setUseAgentAPI={setUseAgentAPI}
                setDbConfig={setDbConfig}
                onSelectPrompt={(promptText) => {
                    setSystemPrompt(promptText);
                }}
            />
        </div>
    );
};

export default ChatWindow;