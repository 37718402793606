import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SettingsModal.css';

const ChatSettingsModal = ({
  isOpen,
  onClose,
  useRAG,
  setUseRAG,
  setSelectedIds,
  setUseText2SQL,
  useText2SQL,
  setDbConfig,
  useAgentAPI,
  setUseAgentAPI,
  onSelectPrompt = () => {} // Default to a no-op function
}) => {
  const [names, setNames] = useState([]);
  const [selectedName, setSelectedName] = useState('');
  const [selectedIds, setInternalSelectedIds] = useState([]);
  const [activeTab, setActiveTab] = useState('Retrieval');
  const [databases, setDatabases] = useState([]);
  const [selectedDatabase, setSelectedDatabase] = useState('');
  const [prompts, setPrompts] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [showFullText, setShowFullText] = useState(false);

  const baseURL = 'https://mdlpearl.ddns.net';
  const DISPLAY_LIMIT = 10; // Limit the displayed IDs to 10

  useEffect(() => {
    async function fetchNames() {
      try {
        const response = await axios.get(`${baseURL}/api/names`);
        setNames(response.data);
      } catch (error) {
        console.error('Error fetching names:', error);
      }
    }
    fetchNames();
  }, [baseURL]);

  useEffect(() => {
    async function fetchDatabases() {
      try {
        const response = await axios.get(`${baseURL}/api/remote_bds`);
        setDatabases(response.data);
      } catch (error) {
        console.error('Error fetching databases:', error);
      }
    }
    fetchDatabases();
  }, [baseURL]);

  useEffect(() => {
    async function fetchPrompts() {
      try {
        const response = await axios.get(`${baseURL}/api/prompts`);
        setPrompts(response.data);
      } catch (error) {
        console.error('Error fetching prompts:', error);
      }
    }
    fetchPrompts();
  }, [baseURL]);

  useEffect(() => {
    async function fetchFileIds() {
      if (selectedName) {
        try {
          const response = await axios.get(`${baseURL}/api/files/${selectedName}`);
          setInternalSelectedIds(response.data);
          setSelectedIds(response.data);
        } catch (error) {
          console.error('Error fetching file IDs:', error);
        }
      }
    }
    fetchFileIds();
  }, [selectedName, baseURL, setSelectedIds]);

  useEffect(() => {
    const dbDetails = databases.find(db => db.db_name === selectedDatabase);
    if (dbDetails) {
      console.log('Updating dbConfig with:', dbDetails);
      setDbConfig(dbDetails);
    }
  }, [selectedDatabase, databases, setDbConfig]);

  if (!isOpen) return null;

  const handleSelect = (event) => {
    const promptId = event.target.value;
    const prompt = prompts.find(p => p.id.toString() === promptId);
    setSelectedPrompt(prompt || null);
    setShowFullText(false);
    if (prompt) {
      onSelectPrompt(prompt.prompt_text);
    }
    console.log('Selected Prompt:', prompt);
  };

  const handleSave = () => {
    onClose();
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Chat Settings</h2>

        <div className="tabs">
          {['Retrieval', 'Text2SQL', 'Agents', 'Prompts', 'Other'].map((tab) => (
            <button
              key={tab}
              className={`tab-button ${activeTab === tab ? 'active' : ''}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>

        <div className="tab-content">
          {activeTab === 'Retrieval' && (
            <div>
              <h3><strong>Retrieval Settings</strong></h3>
              <div className="setting-item">
                <label>
                  <input
                    type="checkbox"
                    checked={useRAG}
                    onChange={e => setUseRAG(e.target.checked)}
                  />
                  Use RAG
                </label>
              </div>

              <div className="setting-item">
                <label>
                  Select a Name:
                  <select value={selectedName} onChange={(e) => setSelectedName(e.target.value)}>
                    <option value="">--Select a Name--</option>
                    {names.map((name, index) => (
                      <option key={index} value={name}>
                        {name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>

              {selectedIds.length > 0 && (
                <div className="setting-item">
                  <h3>Selected File IDs <small>({Math.min(DISPLAY_LIMIT, selectedIds.length)} of {selectedIds.length})</small>:</h3>
                  <ul>
                    {selectedIds.slice(0, DISPLAY_LIMIT).map((id, index) => (
                      <li key={index}>{id}</li>
                    ))}
                  </ul>
                  {selectedIds.length > DISPLAY_LIMIT && (
                    <div>...and {selectedIds.length - DISPLAY_LIMIT} more</div>
                  )}
                </div>
              )}
            </div>
          )}
          {activeTab === 'Text2SQL' && (
            <div>
              <h3><strong>Text2SQL Settings</strong></h3>
              <div className="setting-item">
                <label>
                  <input
                    type="checkbox"
                    checked={useText2SQL}
                    onChange={e => setUseText2SQL(e.target.checked)}
                  />
                  Use Text2SQL
                </label>
              </div>
              <div className="setting-item">
                <label>
                  Select a Database:
                  <select value={selectedDatabase} onChange={(e) => setSelectedDatabase(e.target.value)}>
                    <option value="">--Select a Database--</option>
                    {databases.map((db) => (
                      <option key={db.db_name} value={db.db_name}>
                        {db.db_name}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
            </div>
          )}
          {activeTab === 'Agents' && (
            <div>
              <h3><strong>Agents Settings</strong></h3>
              <div className="setting-item">
                <label>
                  <input
                    type="checkbox"
                    checked={useAgentAPI}
                    onChange={e => setUseAgentAPI(e.target.checked)}
                  />
                  Use Agents
                </label>
              </div>
            </div>
          )}
          {activeTab === 'Prompts' && (
            <div>
              <h3>Select a System Prompt</h3>
              <select onChange={handleSelect} value={selectedPrompt?.id || ''}>
                <option value="">Select a prompt</option>
                {prompts.map(prompt => (
                  <option key={prompt.id} value={prompt.id}>
                    {prompt.prompt_name}
                  </option>
                ))}
              </select>
              {selectedPrompt && (
                <div>
                  <h3>Selected Prompt Details</h3>
                  <table>
                    <thead>
                      <tr>
                        <th>Prompt Name</th>
                        <th>Prompt Text</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{selectedPrompt.prompt_name}</td>
                        <td>
                          <div style={{ maxWidth: '400px', display: 'flex', alignItems: 'center' }}>
                            <span>
                              {showFullText ? selectedPrompt.prompt_text : `${selectedPrompt.prompt_text.substring(0, 100)}...`}
                            </span>
                            {selectedPrompt.prompt_text.length > 100 && (
                              <button onClick={() => setShowFullText(!showFullText)}>
                                {showFullText ? 'Show Less' : 'Show More'}
                              </button>
                            )}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )}
          {activeTab === 'Other' && (
            <div>
              <h3><strong>Other Settings</strong></h3>
            </div>
          )}
        </div>

        <button onClick={handleSave}>Close</button>
      </div>
    </div>
  );
};

export default ChatSettingsModal;