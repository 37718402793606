import React, { useEffect, useState } from "react";
import axios from "axios";
import DataViewSidebar from "./DataViewSidebar";
import NetworkGraph from "./NetworkGraph";
import "./DataView.css";

const DataView = () => {
  const [directories, setDirectories] = useState([]);
  const [selectedDirectory, setSelectedDirectory] = useState("");
  const [directoryData, setDirectoryData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState("text");
  const [graphData, setGraphData] = useState({ nodes: [], edges: [] });
  const [error, setError] = useState("");
  const [allFiles, setAllFiles] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const filesPerPage = 100;
  const [searchTerm, setSearchTerm] = useState("");
  const [fileCount, setFileCount] = useState(0);
  const [query, setQuery] = useState("");

  const baseURL = "https://mdlpearl.ddns.net";

  useEffect(() => {
    const fetchFiles = (page) => {
      const offset = page * filesPerPage;
      axios
        .get(`${baseURL}/api/all_files_sorted`, {
          params: { offset, limit: filesPerPage },
        })
        .then((response) => {
          setAllFiles(response.data || []);
        })
        .catch((error) => {
          console.error("Error fetching all files:", error);
          setError("Failed to fetch files.");
        });
    };

    fetchFiles(currentPage);

    axios
      .get(`${baseURL}/api/directories`)
      .then((response) => {
        setDirectories(response.data || []);
      })
      .catch((error) => {
        console.error("Error fetching directories:", error);
      });
  }, [currentPage]);

  useEffect(() => {
    if (selectedDirectory) {
      axios
        .get(`${baseURL}/api/get_directory_data?directory=${selectedDirectory}`)
        .then((response) => {
          setDirectoryData(response.data || []);
        })
        .catch((error) => {
          console.error("Error fetching directory data:", error);
          setError("Failed to load directory data. Please try again.");
        });
    } else {
      setDirectoryData([]);
    }
  }, [selectedDirectory]);

  const handleFileSelection = (file) => {
    setSelectedFile(file);
    setGraphData({ nodes: [], edges: [] });
  };

  const handleVisualize = () => {
    if (selectedFile) {
      let rawData;
      if (selectedColumn === "kn_graph" && selectedFile.kn_graph) {
        rawData = selectedFile.kn_graph;
      } else if (selectedColumn === "kn_graph_full" && selectedFile.kn_graph_full) {
        rawData = selectedFile.kn_graph_full;
      } else {
        console.error("Selected column data not available for graph visualization");
        return;
      }

      const structuredData = structureGraphData(rawData);
      setGraphData(structuredData);
    }
  };

  const structureGraphData = (rawData) => {
    try {
      const jsonData = JSON.parse(rawData);
      const nodes = [];
      const edges = [];

      jsonData.forEach((item) => {
        nodes.push({ id: item.node_1, label: item.node_1 });
        nodes.push({ id: item.node_2, label: item.node_2 });
        edges.push({ from: item.node_1, to: item.node_2, label: item.edge });
      });

      return { nodes, edges };
    } catch (e) {
      console.error("Failed to parse graph data:", e);
      return { nodes: [], edges: [] };
    }
  };

  const handleSearch = () => {
    if (searchTerm.trim()) {
      axios
        .get("/api/search_files", { params: { searchTerm } })
        .then((response) => {
          console.log("Search response data:", response.data);

          if (response.data && response.data.results) {
            setAllFiles(response.data.results);
            setFileCount(response.data.fileCount);
            setQuery(response.data.query || "No query generated");
          } else {
            setAllFiles([]);
            setFileCount(0);
            setQuery("No query generated");
          }
        })
        .catch((error) => {
          console.error("Error searching files:", error);
          setError("Failed to search files.");
        });
    }
  };

  const renderContent = () => {
    if (!selectedFile) {
      return <p>Select a file to view its content.</p>;
    }

    return (
      <div>
        <h4>{selectedFile.file_name}</h4>
        <select
          value={selectedColumn}
          onChange={(e) => setSelectedColumn(e.target.value)}
        >
          <option value="text">Text</option>
          <option value="title_summary">Title Summary</option>
          <option value="kn_graph">KN Graph</option>
          <option value="key_concepts">Key Concepts</option>
          <option value="kn_graph_full">KN Graph Full</option>
          <option value="job_number">Job Number</option>
        </select>
        {selectedColumn === "kn_graph" || selectedColumn === "kn_graph_full" ? (
          <>
            <button onClick={handleVisualize}>Visualize Graph</button>
            {graphData.nodes.length > 0 && (
              <NetworkGraph
                nodesData={graphData.nodes}
                edgesData={graphData.edges}
              />
            )}
          </>
        ) : (
          <div style={{ whiteSpace: "pre-wrap" }}>
            {selectedFile[selectedColumn] || "No content available"}
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      className="data-view-container"
      style={{ display: "flex", flexDirection: "row" }}
    >
      <DataViewSidebar
        directories={directories}
        selectedDirectory={selectedDirectory}
        setSelectedDirectory={setSelectedDirectory}
        directoryData={directoryData}
        allFiles={allFiles}
        handleFileSelection={handleFileSelection}
        error={error}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        filesPerPage={filesPerPage}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleSearch={handleSearch}
        fileCount={fileCount}
        query={query}
      />
      <div className="content" style={{ padding: "20px", flex: 1 }}>
        <h2>File Content</h2>
        {renderContent()}
      </div>
    </div>
  );
};

export default DataView;
