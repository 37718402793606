// NetworkGraph.js
import React, { useEffect, useRef } from 'react';
import { DataSet, Network } from 'vis-network/standalone/esm/vis-network';

const NetworkGraph = ({ nodesData, edgesData }) => {
    const networkContainer = useRef(null);

    useEffect(() => {
        // Use a Set to track added node IDs and ensure uniqueness
        const uniqueNodes = new Set();
        const nodes = new DataSet();
        const edges = new DataSet();

        // Add nodes uniquely
        nodesData.forEach(node => {
            if (!uniqueNodes.has(node.id)) {
                nodes.add(node);
                uniqueNodes.add(node.id);
            }
        });

        // Add edges normally since they depend on valid node IDs
        edgesData.forEach(edge => {
            edges.add(edge);
        });

        // Set up the vis Network options
        const options = {
            autoResize: true,
            height: '100%',
            width: '100%',
            layout: {
                improvedLayout: true,
            },
            physics: {
                enabled: true,
            }
        };

        // Create a new Network instance
        const network = new Network(networkContainer.current, { nodes, edges }, options);

        // Cleanup on component unmount
        return () => {
            network.destroy();
        };
    }, [nodesData, edgesData]);

    return <div ref={networkContainer} style={{ height: '500px' }} />;
};

export default NetworkGraph;