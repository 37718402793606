import React from 'react';
import './DataViewSidebar.css';

const DataViewSidebar = ({
    directories,
    selectedDirectory,
    setSelectedDirectory,
    directoryData,
    allFiles,
    handleFileSelection,
    error,
    currentPage,
    setCurrentPage,
    filesPerPage,
    searchTerm,
    setSearchTerm,
    handleSearch,
    fileCount,
    query
}) => (
    <div className="data-view-sidebar">
        <h2>Directory Selection</h2>
        <select value={selectedDirectory} onChange={(e) => setSelectedDirectory(e.target.value)}>
            <option value="">Select a Directory</option>
            {directories.map((directory, index) => (
                <option key={index} value={directory}>
                    {directory}
                </option>
            ))}
        </select>
        <h3>Search</h3>
        <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Enter filename or ;; Followed by Text Query"
        />
        <button onClick={handleSearch}>Search</button>

        {fileCount > 0 && (
            <div>
                <h4>Number of Matching Files:</h4>
                <span>{fileCount}</span>
            </div>
        )}

        {query && query !== "No query generated" && (
            <div>
                <h4>Generated Query:</h4>
                <pre>{query}</pre>
            </div>
        )}

        <h3>Files from Directory</h3>
        {error && <div className="error-message">{error}</div>}
        <ul>
            {directoryData.map((data, index) => (
                <li key={index} onClick={() => handleFileSelection(data)}>
                    {data.file_name}
                </li>
            ))}
        </ul>
        <h3>All Files</h3>
        <ul>
            {allFiles.map((file, index) => (
                <li key={index} onClick={() => handleFileSelection(file)}>
                    {file.file_name}
                </li>
            ))}
        </ul>
        <div className="pagination-controls">
            <button onClick={() => setCurrentPage(prev => Math.max(prev - 1, 0))} disabled={currentPage === 0}>
                Previous
            </button>
            <button onClick={() => setCurrentPage(prev => prev + 1)}>
                Next
            </button>
        </div>
    </div>
);

export default DataViewSidebar;
