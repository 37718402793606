import React, { useEffect, useRef } from 'react';
import { DataSet, Network } from 'vis-network/standalone/esm/vis-network';

const CombinedNetworkGraph = ({ fileDataSets, onRenderComplete }) => {
    const networkContainer = useRef(null);
    const nodeColors = {};

    useEffect(() => {
        const nodes = new DataSet();
        const edges = new DataSet();

        const colors = fileDataSets.map((_, index) => `hsl(${index * 137.508}, 50%, 50%)`);

        fileDataSets.forEach(({ fileName, nodesData, edgesData }, index) => {
            const color = colors[index];
            nodeColors[fileName] = color;

            nodesData.forEach(({ id, label }) => {
                if (!nodes.get(id)) nodes.add({ id, label, color });
            });

            edgesData.forEach(({ from, to, label }) => {
                edges.add({ from, to, label });
            });
        });

        const options = {
            autoResize: true,
            height: '100%',
            width: '100%',
            layout: {
                improvedLayout: true,
            },
            physics: {
                enabled: true,
            },
            edges: {
                color: {
                    color: '#848484',
                    highlight: '#848484',
                    hover: '#848484',
                    inherit: 'from',
                },
                width: 1,
                smooth: {
                    type: 'continuous',
                },
                font: {
                    size: 14,
                    color: '#343434',
                    face: 'arial',
                    align: 'horizontal',
                    strokeWidth: 3,
                    strokeColor: '#ffffff'
                },
                chosen: {
                    edge: (values, id, selected, hovering) => {
                        if (selected || hovering) {
                            values.label = true;
                            values.font = { strokeWidth: 0, color: '#343434' };
                        } else {
                            values.label = false;
                        }
                    }
                }
            }
        };

        const network = new Network(networkContainer.current, { nodes, edges }, options);

        // Add stabilization complete handler
        const handleComplete = () => {
            // Ensure this only runs once per life cycle to avoid endless loops
            if (network && typeof onRenderComplete === 'function') {
                onRenderComplete();
            }
        };

        network.once('stabilizationIterationsDone', function () {
            network.setOptions({ physics: false });
            // Add a delayed check to manage graphic completion
            setTimeout(handleComplete, 300);  // Execute handle after slight delay to aid completion
        });

        // Clean up the network to ensure no memory leaks or lingering listeners
        return () => {
            network.off('afterDrawing', handleComplete);  // Remove listeners on unmount
            network.destroy();
        };
    }, [fileDataSets, onRenderComplete]);

    return (
        <div>
            <div ref={networkContainer} style={{ height: '500px' }} />
            <div>
                {Object.entries(nodeColors).map(([fileName, color]) => (
                    <div key={fileName} style={{ display: 'inline-block', margin: '5px' }}>
                        <span style={{ display: 'inline-block', width: '15px', height: '15px', backgroundColor: color, marginRight: '5px' }}></span>
                        {fileName}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CombinedNetworkGraph;